import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import { useLocation } from '@ubo/losse-sjedel';
import { rootLoader as loader, useLoaderData, Root } from '@ubo/losse-sjedel';

import styles from '~/global.css';
import PageNotFound from '~/components/layout/PageNotFound';
import stylesProgress from 'nprogress/nprogress.css';

import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    href: 'https://use.typekit.net',
    rel: 'preconnect',
    crossOrigin: 'anonymous'
  },
  {
    href: 'https://p.typekit.net',
    rel: 'preconnect',
    crossOrigin: 'anonymous'
  },
  {
    href: 'https://use.typekit.net/eeh2mjd.css',
    rel: 'preload',
    as: 'style'
  },
  {
    href: 'https://use.typekit.net/eeh2mjd.css',
    rel: 'stylesheet'
  },
  {
    href: stylesProgress,
    rel: 'stylesheet'
  },
  {
    href: styles,
    rel: 'preload',
    as: 'style'
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

export default function App() {
  const data = useLoaderData<RootProps>();
  const location = useLocation();
  let lang = 'en';
  const path = location.pathname.replace(/\//g, '');

  if (path.length === 2) {
    lang = path;
  }

  return <Root {...data} lang={lang} />;
}